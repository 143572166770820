.bodyClassName {
  background: #000001 !important;
  color: #fff !important;
  gap: 20px !important;
}

.toastClassName {
  background: #000001 !important;
  border-radius: 4px;
}
