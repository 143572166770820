@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter:wght@400,500,600&display=swap');

body {
  margin: 0;
  font-family: 'Lato', 'Inter', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI`', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #498eda;
  --phoneNumberInputHeight: 30px;
  --grey-main: #eaeaea;
  --grey-dark: #abb0be;
  --grid-color: #f2f5f9;
}

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(237, 233, 233, 0.798);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ag-center-cols-container > .active-row {
  pointer-events: none;
}

.ag-center-cols-container > .active-row::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  --addColWidth: 50px;
  width: calc(100% - var(--addColWidth));
  border: 2px solid purple !important;
  z-index: 4;
}

/* .ag-center-cols-container>.active-row::after {
  content: var(--user);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 100%;
  border: 1px solid #76787e;
  padding: 8px 16px;
  background-color: rgb(223 231 236) !important;
  z-index: 2;
  transition: .25s linear;
  opacity: 1;
} */

.ag-center-cols-container > .active-row:hover {
  opacity: 0;
}

.ag-root
  > .ag-floating-bottom
  > .ag-floating-bottom-viewport
  > .ag-floating-bottom-container
  > .ag-row
  > .ag-cell
  > .ag-react-container
  .MuiSwitch-root,
.ag-root
  > .ag-floating-bottom
  > .ag-floating-bottom-viewport
  > .ag-floating-bottom-container
  > .ag-row
  > .ag-cell
  > .ag-react-container
  > .cell-container
  .MuiCheckbox-root,
.ag-root
  > .ag-floating-bottom
  > .ag-floating-bottom-viewport
  > .ag-floating-bottom-container
  > .ag-row
  > .ag-cell
  > .ag-react-container
  > .cell-container.selectBoxRenderer,
.ag-root
  > .ag-floating-bottom
  > .ag-floating-bottom-viewport
  > .ag-floating-bottom-container
  > .ag-row
  > .ag-cell
  > .ag-react-container
  > .cell-container.labelRenderer {
  /* hiding switch and checkbx from pinned row */
  display: none;
  pointer-events: none;
}

.regular-checkbox:hover {
  cursor: pointer;
}
div.PhoneInput > input[type='tel'].PhoneInputInput {
  /*phone number input style*/
  border: 1px solid #ddd;
  box-shadow: rgb(10 10 10 / 7%) 0px 14px 17px 0px;
  border-radius: 3px;
  height: var(--phoneNumberInputHeight);
  padding: 4px 8px;
  outline: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: '#26262';
}

div.PhoneInput {
  margin-top: 8px;
}

div.PhoneInput > input[type='tel'].PhoneInputInput:focus {
  border: 1px solid var(--primary);
}

.select-box-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

/* grid header*/
.ag-theme-alpine .ag-header {
  border-bottom: none !important;
}

.notification-container {
  top: 12% !important;
}

:focus-visible {
  outline: none;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-in-left {
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-1000px);
    opacity: 0;
  }
}
.slide-out-left {
  animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(1000px);
    opacity: 0;
  }
}
.slide-out-right {
  animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes flip-out-ver-left {
  0% {
    transform: rotateY(0);
    opacity: 1;
  }
  100% {
    transform: rotateY(-70deg);
    opacity: 0;
  }
}
.flip-out-ver-left {
  animation: flip-out-ver-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes flip-in-ver-right {
  0% {
    transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0);
    opacity: 1;
  }
}
.flip-in-ver-right {
  animation: flip-in-ver-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
