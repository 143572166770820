.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ! ================  global styles for pdf and automation custom toast starts here ===================  */

.automation-toast {
  display: flex;
  padding: 6px 12px;
  background: #e0c8fe;
}
.automation-toast div:nth-child(2) {
  flex: 1 !important;
}
.autmation-toast-container {
  align-items: center;
  gap: 8px;
}
.automation-list-container {
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-gap: 0;
}

.automation-list-item {
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  color: #6f23e5;
  border-bottom: 0.5px solid rgba(111, 35, 229, 0.3);
  padding: 8px 0px;
  padding-left: 6px;
}

.automation-list-item:last-child {
  border-bottom: none;
}

.automation-icon {
  animation: rotating 0.5s linear infinite;
}

.pdf-toast-container {
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  padding: 0px 6px;
  background: #d9ebd7;
  color: #449f34;
}

.pdf-toast-content {
  background: #d9ebd7;
}

.pdf-toast-error-container {
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  padding: 0px 6px;
  background: #ffc0c0;
  color: #e0292a;
}

.pdf-toast-error-content {
  background: #ffc0c0;
}

.pdf-toast-content div:nth-child(2),
.pdf-toast-error-content div:nth-child(2) {
  padding-left: 8px;
}

.download-pdf-toast-icon,
.import-icon {
  transform: scale(1.5);
}

@keyframes rotating {
  0% {
    transform: rotate(0deg) scale(1.5);
  }
  100% {
    transform: rotate(360deg) scale(1.5);
  }
}

.error-toast {
  display: flex;
  padding: 0px 16px;
  gap: 8px;
  background: #f9d4d4;
}

.error-toast-text {
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  color: #e02a2a;
  background: #f9d4d4;
  padding: 8px 0px;
}

.common-toast {
  display: flex;
  padding: 0px 16px;
  gap: 8px;
  background: #cee3ee;
}

.common-toast-text {
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  color: #0d70ab;
  background: #cee3ee;
  padding: 8px 0px;
}

.import-toast {
  display: flex;
  padding: 0px 16px;
  gap: 8px;
  background: #eae2e0;
}

.import-toast-text {
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  color: #996d66;
  background: #eae2e0;
  padding: 8px 0px;
}

.export-toast {
  display: flex;
  padding: 0px 16px;
  gap: 8px;
  background: #fcedd6;
}

.export-toast-text {
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  color: #de8700;
  background: #fcedd6;
  padding: 8px 0px;
}

/* ! ================  global styles for pdf and automation custom toast starts here ===================  */
