.template-list-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 455px;
  overflow: auto;
  padding-right: 5px;
  /* max-width: 469px; */
  /* width: 469px; */
}
.template-list-container::-webkit-scrollbar {
  display: flex !important;
}

.ag-menu-separator {
  display: grid;
}
.ag-menu-list.ag-focus-managed {
  padding: 0;
  overflow: hidden;
}
.ag-menu.ag-ltr.ag-popup-child {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 4px;

  /* width: 193px; */
  height: auto;

  background: #ffffff;
  /* Nav/Border */

  border: 1px solid #bfcce0;
  box-shadow: 0px 4px 12px rgba(127, 154, 193, 0.16);
  border-radius: 4px;
}
.ag-menu-list {
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(32px, 1fr));
  gap: 4px;
  padding: 12px;
}
.ag-menu-option {
  box-sizing: border-box;
  display: grid !important;
  grid-template-columns: 1fr 4fr;
  align-items: start;
  padding: 8px 4px;
  column-gap: 12px;

  width: 169px;
  height: 32px;

  background: #ffffff;

  border-radius: 5px;
  /* Inside auto layout */
}

.ag-menu-option-part.ag-menu-option-text {
  width: 31px;
  height: 14px;
  margin: 0 0 0 -30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/Dark */

  color: #262626;

  /* Inside auto layout */
}
.ag-menu-option-part.ag-menu-option-icon {
  margin: -6px 23px 0px -11px;

  /* Inside auto layout */

  /* Inside auto layout */
}

.ag-menu-option-part.ag-menu-option-shortcut {
  padding: 0px !important;
}

.ag-menu-option-part.ag-menu-option-popup-pointer {
  padding: 0px !important;
}

.move-modal {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: 350px !important;
  overflow-y: scroll;
}

.container-dash-detail {
  width: 300px;
  box-sizing: border-box;
  margin: 20px 20px;
  padding: 0px 0 15px 0;

  /* position: absolute; */
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  /* Text/White */

  background: #ffffff;
  /* Nav/Border */

  border: 1px solid #bfcce0;
  border-radius: 6px;
}

.dashSubText {
  width: 62px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  /* Text/Nav */

  color: #556a89;
}

.dashFooterText {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  /* Text/Nav */

  color: #556a89;
  white-space: nowrap;
  overflow: overlay;
  text-overflow: ellipsis;
}

.fileIcon {
  color: #b1c3df;
  width: 12px;
  height: 15px;
  padding: 0 10px;
}

.location {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  /* Text/Dark */

  color: #262626;
}

.list {
  width: 1024px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
}
.text-continer-dash {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.text-sections {
  max-width: 100vh !important;
}
.chart-top-bar {
  display: flex;
  padding: 10px;
  width: 874px;
  margin: 35px 0 100px 0;

  /* Nav/Search BG */

  background: #f2f5f9;
  border-radius: 6px;
}

.chart-bottom-bar {
  display: flex;
  justify-content: space-around;
  width: 874px;
  height: 40px;
  background: #ffffff;
  /* Nav/Border */
  margin: 10px 0 0 0;

  border: 1px solid #bfcce0;
  border-radius: 6px;
}

.dashHeadText {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;

  /* Text/Nav */

  color: #556a89;

  /* Inside auto layout */
  white-space: nowrap;
  overflow: overlay;
  text-overflow: ellipsis;
}
.dashHeaderText {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  /* Text/Dark */

  color: #262626;
  white-space: nowrap;
  overflow: overlay;
  text-overflow: ellipsis;
}
.dashText {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  margin: 0 30px 10px 30px;
  /* identical to box height */

  /* Text/Nav */

  color: #556a89;
}
.dashTextMultiVal {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin: 0 30px 10px 0px;
  /* identical to box height */

  /* Text/Nav */

  color: #556a89;
}

.pill-dot {
  width: 6px;
  height: 6px;

  /* Brand/Cyan */

  background: #08c9e5;
  border-radius: 3px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 12px;
}

.dropDownItemListContainer {
  width: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  width: 100%;
  height: auto;
  max-height: 340px;
}

.MuiButton-text {
  color: var(--primary);
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  left: -15px;
}

.regular-checkbox {
  appearance: none;
  border: 1.34483px solid rgba(0, 0, 0, 0.4);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.document-options {
  min-height: 300px;
  align-items: center;
  padding-top: 10px;
  display: block;
  flex-direction: column;
  align-items: flex-start;
  flex-flow: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  height: 300px;
  width: 100%;
}

.document-options-box {
  min-height: 300px;
  align-items: center;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-flow: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  height: 300px;
  width: 100%;
}

.modal-document-options {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  flex-flow: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  height: 175px;
  width: 100%;
}

.date-modal {
  min-height: 150px;
  max-height: 250px;
  margin-bottom: 50px;
  align-items: center;
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  flex-flow: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  height: 300px;
  width: 100%;
}

.custom-page-modal {
  min-height: 150px;
  max-height: 250px;
  margin-bottom: 50px;
  align-items: center;
  padding-top: 10px;
  flex-flow: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  height: 300px;
  width: 100%;
}

.date-modal::-webkit-scrollbar {
  -webkit-appearance: none;
}

.date-modal::-webkit-scrollbar:vertical {
  width: 11px;
}

.date-modal::-webkit-scrollbar:horizontal {
  height: 11px;
}

.date-modal::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.document-options::-webkit-scrollbar {
  -webkit-appearance: none;
}

.document-options::-webkit-scrollbar:vertical {
  width: 11px;
}

.document-options::-webkit-scrollbar:horizontal {
  height: 11px;
}

.document-options::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-document-options::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px !important;
  height: 30px !important;
}

.modal-document-options::-webkit-scrollbar:vertical {
  width: 11px;
}

.modal-document-options::-webkit-scrollbar:horizontal {
  height: 11px;
}

.modal-document-options::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: #000000 !important;
}

@media screen and (max-width: 760px) {
  .regular-checkbox {
    width: 17px;
    height: 17px;
  }
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
  border: 1.34483px solid rgb(79, 128, 19, 0.4);
}

.regular-checkbox:checked {
  border: 1.34483px solid rgb(79, 128, 19, 0.4);
}

.regular-checkbox:checked:after {
  content: url('../assets/img/tick.svg');
  font-size: 14px;
  position: relative;
  top: 1px;
  /* left: 1.3px; */
  color: #4f8013;
}

/* .carousel .thumb:hover {
    border: 3px solid #fff;
  } */

.react-multi-email {
  border: 1px solid #d9d9de !important;
  box-sizing: border-box;
  min-height: 43.63px;
  background-color: #f9f9f9 !important;
}

.react-multi-email.empty > span[data-placeholder] {
  background-color: #f9f9f9 !important;
  color: #5e5e5e !important;
}

.react-multi-email > input {
  background-color: #f9f9f9 !important;
}

.caret {
  animation-name: blink;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  opacity: 1;
  border-left: 1px solid black;
}

@keyframes blink {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.input {
  width: 100%;
  height: 45px;
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-container {
  border: none;
  outline: none;
  width: 100%;
}

.location-autocomplete {
  z-index: 1;
  width: 100%;
  margin-bottom: 16px;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 100;
  width: 97%;
  margin: auto;
}

.location-label {
  margin: 13px 0 8px 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #556a89;
}

.suggestion-item {
  background-color: #ffffff;
  cursor: pointer;
  padding: 10px;
  text-align: left;
}

.suggestion-item:hover {
  background-color: #fafafa;
}

.picker-container {
  display: flex;
  margin-top: 20px;
  flex-flow: row;
  justify-content: center;
}
.picker-class {
  box-shadow: none !important;
}

/* .menu div {
  position: relative !important;
  } */

/* Auto layout */

/* .react-contexify.react-contexify__will-enter--scale {
    top:0 !important;
    left:0 !important;
    right:0 !important;
  } */

.dash-detail-container {
  display: grid;
  /* border: 1px solid #BFCCE0; */
  height: auto;
  grid-template-columns: 1fr 3fr;
  width: 100%;
  margin-top: 30px;

  gap: 30px;
}
.dash-detail-sidebar {
  display: grid;
  grid-template-rows: 1fr;
  /* border: 1px solid #BFCCE0; */
  /* flex-direction: column; */
  align-items: center;
  /* padding: 25px; */
  gap: 12px;
  overflow-y: scroll;
  height: 70vh;
  width: 100%;
}

.header-section {
  display: flex;
  align-items: center;
}
.value-section {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px;
}
/* .seperator-line {
  border: 1px solid #e4eaf2;
} */
.line {
  width: 436px;
  border-bottom: 1px solid #e4eaf2;
  margin: auto;
}
.more-items-badge {
  height: 36px;
  min-width: 36px;
  background-color: #e4eaf2;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.more-items-badge svg {
  height: 14px;
  width: 14px;
}
.view-all {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  /* Brand/Blue */

  color: #337eed;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.dash-detail-main {
  display: grid;
  grid-template-rows: 1fr 5fr 1fr;
  width: 100%;
  /* border: 1px solid #BFCCE0; */
  /* flex-direction: column; */
  /* padding: 25px 0; */
}
.dash-detail-sidebar .item {
  box-sizing: border-box;
  border: 1px solid #bfcce0;
  border-radius: 6px;
  display: grid;
  grid-template-rows: 1fr;
  /* flex-direction: column; */
  height: auto;
  width: 100%;
}
.dash-detail-main .dash-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  height: 40px;
  border: 1px solid #bfcce0;
  /* Nav/Search BG */

  background: #f2f5f9;
  border-radius: 6px;
}

.dash-detail-main .dash-detail-footer {
  width: 100%;
  height: 55px;
  background: #ffffff;
  /* Nav/Border */
  box-sizing: border-box;
  border: 1px solid #bfcce0;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  overflow: scroll;
}

.dash-detail-main .dash-detail-chart {
  display: flex;
  width: 100%;
  height: 455px;
  background: #ffffff;
  /* Nav/Border */
  box-sizing: border-box;

  border-radius: 6px;
}
.hlpIcon {
  padding: 0 10px;
}
.helper-icons {
  display: flex;
}
.bread-crumbs {
  display: flex;
}

.chart-center-text {
  position: relative;
  left: 50%;
  top: 40%;
  text-align: center;
}

.section-1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.title {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.back-icon {
  margin: 0 20px 0 0px;
}
.navigation {
  display: flex;
  justify-content: flex-start;
  width: auto;
}
.title-holder {
  display: flex;
  justify-content: space-between;
  width: auto;
}
.title-section {
  display: flex;
  align-items: center;
  width: auto;
  justify-content: flex-start;
}
.search-icon-section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-icon {
  margin: 0 10px;
}
.vertical-separator {
  border: 1px solid #e4eaf2;
}
.dashboardHeadingText {
  width: auto;
  height: 20px;
  left: 76px;
  top: 78px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* Text/Dark */

  color: #262626;
  margin: 0 10px 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboardHeadingSubText {
  margin: 0;
  width: 70px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  /* Text/Dark */

  color: #262626;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-data-available {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 8px;
  gap: 10px;

  width: 106px;
  height: 20px;

  background: #f2f5f9;
  border-radius: 2px;

  /* Inside auto layout */
  margin-left: 15px;
}
.no-data-text {
  width: 98px;
  margin: 0;
  height: 20px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 200% */

  /* Text/Nav */

  color: #556a89;
  white-space: nowrap;
}

.move-modal {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: 350px !important;
  overflow-y: scroll;
}

.dropDownItemListContainer {
  width: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  width: 100%;
  height: auto;
  max-height: 340px;
}

.MuiButton-text {
  color: var(--primary);
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  left: -15px;
}

.regular-checkbox {
  appearance: none;
  border: 1.34483px solid rgba(0, 0, 0, 0.4);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.document-options {
  min-height: 300px;
  align-items: center;
  padding-top: 10px;
  display: block;
  flex-direction: column;
  align-items: flex-start;
  flex-flow: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  height: 300px;
  width: 100%;
}

.document-options-box {
  min-height: 300px;
  align-items: center;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-flow: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  height: 300px;
  width: 100%;
}

.modal-document-options {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  flex-flow: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  height: 175px;
  width: 100%;
}

.date-modal {
  min-height: 150px;
  max-height: 250px;
  margin-bottom: 50px;
  align-items: center;
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  flex-flow: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  height: 300px;
  width: 100%;
}

.date-modal::-webkit-scrollbar {
  -webkit-appearance: none;
}

.date-modal::-webkit-scrollbar:vertical {
  width: 11px;
}

.date-modal::-webkit-scrollbar:horizontal {
  height: 11px;
}

.date-modal::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.document-options::-webkit-scrollbar {
  -webkit-appearance: none;
}

.document-options::-webkit-scrollbar:vertical {
  width: 11px;
}

.document-options::-webkit-scrollbar:horizontal {
  height: 11px;
}

.document-options::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-document-options::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px !important;
  height: 30px !important;
}

.modal-document-options::-webkit-scrollbar:vertical {
  width: 11px;
}

.modal-document-options::-webkit-scrollbar:horizontal {
  height: 11px;
}

.modal-document-options::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: #000000 !important;
}

@media screen and (max-width: 760px) {
  .regular-checkbox {
    width: 17px;
    height: 17px;
  }
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
  border: 1.34483px solid rgb(79, 128, 19, 0.4);
}

.regular-checkbox:checked {
  border: 1.34483px solid rgb(79, 128, 19, 0.4);
}

.regular-checkbox:checked:after {
  content: url('../assets/img/tick.svg');
  font-size: 14px;
  position: relative;
  top: 1px;
  /* left: 1.3px; */
  color: #4f8013;
}

.carousel .thumb.selected {
  border: 3px solid #fff !important;
}

/* .carousel .thumb:hover {
  border: 3px solid #fff;
} */

.carousel .thumb {
  border: 3px solid #333 !important;
}

.carousel .thumb img {
  height: 50px;
}

.react-multi-email {
  border: 1px solid #d9d9de !important;
  box-sizing: border-box;
  min-height: 43.63px;
  background-color: #f9f9f9 !important;
}

.react-multi-email.empty > span[data-placeholder] {
  background-color: #f9f9f9 !important;
  color: #5e5e5e !important;
}

.react-multi-email > input {
  background-color: #f9f9f9 !important;
}

.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.input {
  width: 100%;
  height: 45px;
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-container {
  border: none;
  outline: none;
  width: 100%;
}

.color-picker-container {
  text-align: center;
}

.picker-container {
  display: flex;
  margin-top: 20px;
  flex-flow: row;
  justify-content: center;
}

.picker-class {
  box-shadow: none !important;
}

/* .menu div {
position: relative !important;
} */

/* Auto layout */

/* .react-contexify.react-contexify__will-enter--scale {
  top:0 !important;
  left:0 !important;
  right:0 !important;
} */

.title-holder {
  display: flex;
  justify-content: space-between;
  width: auto;
}

.title-section {
  display: flex;
  align-items: center;
  width: auto;
  justify-content: flex-start;
}

.search-icon-section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.daily-page-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 8px;
}

.monthly-page-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 8px;
}

.month-card {
  width: auto;
  height: 55px;
  box-sizing: border-box;

  /* Auto layout */
  padding: 9px 23px;
  gap: 8px;

  /* Nav/Search BG */

  /* Brand/Blue */

  border: 1px solid #e4eaf2;
  border-radius: 6px;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}

.active-month-card {
  width: auto;
  height: 55px;
  box-sizing: border-box;

  /* Auto layout */
  padding: 9px 23px;
  gap: 8px;

  /* Nav/Search BG */

  /* Brand/Blue */
  background: #f2f5f9;
  border: 1px solid #337eed;
  border-radius: 6px;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}
.active-month-card .month {
  color: #337eed;
}
.active-month-card .year {
  color: #337eed;
}

.month {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Text/Nav */

  color: #556a89;

  /* Inside auto layout */

  margin: 0;
}

.year {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Icon Dark */

  color: #7e99c1;

  /* Inside auto layout */

  margin: 0;
}

.existing-date-card {
  width: 60px;
  height: 60px;
  background: #ffffff;
  /* Brand/Blue */

  border: 1px solid #e4eaf2;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.active-date-card {
  width: 60px;
  height: 60px;
  background: #f2f5f9;
  /* Brand/Blue */
  box-sizing: border-box;

  /* Nav/Search BG */

  background: #f2f5f9;
  /* Brand/Blue */

  border: 1px solid #337eed;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  cursor: pointer;
}
.bottom {
  width: 568px;
  /* height: 198px;
  overflow: overlay; */
}

.member-access-only-section {
  width: 568px;
  /* height: 440px;
  overflow: overlay; */
}

.share-agent-main {
  display: grid;
  grid-template-rows: 1fr 1fr 2fr;
  max-height: 550px;
  height: 430px;
}
.member-access-only-agent-main {
  display: grid;
  grid-template-rows: 1fr 1fr 2fr;
  max-height: 433px;
  height: 433px;
}

.member-with-access-only {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 468px;
  height: 376px;
}
.share-with-team {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 568px;
  height: 98px;
  /* border: 1px solid; */
}
.member-list {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;

  width: 568px;
  height: 72px;
  overflow: overlay;
  /* grid-template-columns: repeat(auto-fill, minmax(74px, 1fr)); */

  /* border: 1px solid; */
}
.add-team-member {
  width: 568px;
  height: 84px;
}
.member-section {
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow: scroll;
}
.member-only-section {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: scroll;
}
.member-box {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 2fr 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 0px 2px;
  gap: 0px;

  width: 74px;
  min-width: 74px;
  height: 72px;
  min-height: 72px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
  /* border: 1px solid; */
  /* Inside auto layout */
}

.avatar {
  width: 24px !important;
  height: 24px !important;
  align-items: center !important ;
  justify-content: center !important ;
  font-size: medium !important;
  border-radius: 41px;
  cursor: pointer;
}

.team-info {
  /* grid-template-rows: repeat(auto-fill, minmax(14px, 1fr)); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 70px;
  height: 28px;

  /* Inside auto layout */

  margin-bottom: 5px;
}

.team-text {
  width: 70px;
  height: 14px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* Text/Dark */

  color: #262626;

  margin: 0;
  white-space: nowrap;
  overflow: overlay;
  text-overflow: ellipsis;
}

.team-subtext {
  width: 70px;
  height: 14px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* Text/Light */

  color: #8c8c8c;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0;
}

.access-section {
  width: 137px;
  height: 14px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/Nav */

  color: #556a89;
  white-space: nowrap;
}
.add-member-text {
  width: 121px;
  height: 14px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Text/Nav */

  color: #556a89;
  white-space: nowrap;
}
.placeholder-text {
  width: 160px;
  height: 14px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Nav/Border */

  color: #bfcce0;
  white-space: nowrap;
}
.avatar {
  font-size: small;
  cursor: 'pointer';
}
.shareMemberText {
  width: 300px;
  height: 14px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  align-items: center;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.permissionButtonShareAgent {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px 4px 8px;
  gap: 8px;

  width: 60px;
  height: 21px;

  /* Text/White */

  background: #ffffff !important;
  /* Utils/Border */

  border: 1px solid #e4eaf2 !important;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}

.removeShareText {
  width: 40px;
  height: 13px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Red */

  color: #f5222d;

  /* Inside auto layout */
}
.selected-team {
  box-sizing: border-box;

  /* Auto layout */

  display: grid;
  grid-template-rows: 2fr 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 0px 2px;
  gap: 0px;
  isolation: isolate;

  width: 74px;
  height: 72px;
  min-width: 74px;
  min-height: 72px;

  /* Nav/Hover Bg */

  background: #ebf2fd;
  /* Brand/Blue */

  border: 1px solid #337eed;
  border-radius: 4px;
  cursor: pointer;
  /* Inside auto layout */
}
.date-card {
  width: 60px;
  height: 60px;
  background: #ffffff;
  /* Brand/Blue */

  border: 1px solid #e4eaf2;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.date-card:hover {
  background: #f2f5f9;
}

.date-card:hover .month-year {
  display: none;
}

.date-card:hover .add-page-btn {
  display: flex;
  cursor: pointer;
}

.day {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  /* Brand/Blue */

  color: #556a89;
}

.month-year {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Icon Dark */

  color: #7e99c1;
  margin: 10px 5px 5px 5px;
}

.notch {
  margin: 0 0 8px 0;
  width: 20px;
  height: 3px;
  background: #337eed;
  border-radius: 10px;
}
.monthly-notch {
  margin-left: 0%;
  margin-top: -7px;
  width: 40px;
  height: 2px;
  background: #337eed;
  border-radius: 10px;
}

.vertical-separator {
  border: 1px solid #e4eaf2;
}

.no-add-page-btn {
  display: none;
}
.add-page-btn {
  width: 50px;
  height: 17px;

  /* Brand/Blue */

  background: #337eed;
  border-radius: 10.5px;
  display: none;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.add-page-btn-text {
  /* left: 17px;
    top: 40px; */
  height: 12px;
  width: 26px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Text/White */

  color: #ffffff;
}

.today-btn {
  box-sizing: border-box;

  width: 81px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Utils/Border */

  border: 1px solid #e4eaf2;
  border-radius: 4px;
}

.this-month-btn {
  box-sizing: border-box;

  width: 93px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Utils/Border */

  border: 1px solid #e4eaf2;
  border-radius: 4px;
}

.month-selector {
  box-sizing: border-box;

  width: 140px;
  height: 32px;

  /* Nav/Border */

  border: 1px solid #bfcce0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: center;
}

.year-selector {
  box-sizing: border-box;

  width: 140px;
  height: 32px;

  /* Nav/Border */

  border: 1px solid #bfcce0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: center;
}
.year-selector-text {
  width: 57px;
  height: 14px;
  left: 457px;
  top: 206px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/Nav */

  color: #556a89;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-bar {
  display: flex;
  height: 50px;
  justify-content: space-between;
}

.today-btn-text {
  width: 33px;
  height: 14px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Brand/Blue */

  color: #337eed;
}
.this-month-btn-text {
  width: 93px;
  height: 14px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Brand/Blue */

  color: #337eed;
  margin: 0 0px 0 15px;
}

.month-selector-text {
  width: 57px;
  height: 14px;
  left: 457px;
  top: 206px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/Nav */

  color: #556a89;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.helperText {
  width: 468px;
  height: 13px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;

  /* Icon Dark */

  color: #7e99c1;
}

.select-columns {
  width: 420px;
  height: auto;
  display: flex;
}
.all {
  display: flex;
}
.selectAllColumns {
  width: 160px;
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/Nav */

  color: #556a89;
  margin-left: 10px;
}
.selected {
  display: flex;
}
.selectedColumns {
  width: 160px;
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/Nav */

  color: #556a89;
  margin-left: 10px;
}

.column-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 468px;
  height: 330px;
  overflow: scroll;
}
.column-bar {
  box-sizing: border-box;

  width: 464px;
  height: 32px;

  /* Nav/Search BG */

  background: #ffffff;
  /* Brand/Blue */

  border: 1px solid #e4eaf2;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.column-bar-selected {
  box-sizing: border-box;

  width: 464px;
  height: 32px;

  /* Nav/Search BG */

  background: #f2f5f9;
  /* Brand/Blue */

  border: 1px solid #337eed;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.nameandsymbol {
  display: flex;
  height: 13px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;

  /* Text/Nav */

  color: #556a89;

  margin-left: 5px;

  /* Inside auto layout */
}
.col-name {
  width: 44px !important;
  height: 13px !important;

  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  display: flex !important;
  align-items: center !important;

  /* Text/Nav */

  color: #556a89 !important;
  white-space: nowrap !important;

  margin-left: 5px;
  /* Inside auto layout */
}
.tabs {
  display: flex;
  margin-bottom: 15px;
}
.tab1 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 120px;
  height: 32px;

  /* Text/White */

  background: #ffffff;
  white-space: nowrap;
  /* Brand/Blue */

  border-bottom: 2px solid #e4eaf2;
  cursor: pointer;
}
.tab1_active {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 120px;
  height: 32px;

  /* Text/White */

  background: #ffffff;
  white-space: nowrap;
  /* Brand/Blue */

  border-bottom: 2px solid #337eed;
  cursor: pointer;
}

.tab2 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 75px;
  height: 32px;

  /* Text/White */

  background: #ffffff;
  /* Utils/Border */
  cursor: pointer;
  border-bottom: 1px solid #e4eaf2;
}

.tab2_active {
  box-sizing: border-box;
  cursor: pointer;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 75px;
  height: 32px;

  /* Text/White */

  background: #ffffff;
  /* Utils/Border */

  border-bottom: 2px solid #337eed;
}

.column {
  width: 94px;
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Brand/Blue */

  color: #337eed;
}

.header {
  width: 43px;
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Brand/Blue */

  color: #337eed;
}

.header_settings_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 468px;
  height: 428px;
  overflow-y: scroll;
}

.content {
  display: flex;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  gap: 8px;

  width: 464px;
  height: 32px;

  /* Text/White */

  background: #ffffff;

  /* Inside auto layout */

  align-self: stretch;
}
.subheading {
  display: flex;
  margin-left: 10px;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;
  gap: 6px;

  width: 468px;
  height: 32px;

  /* Text/White */

  background: #ffffff;
  /* Utils/Border */

  border: 0px solid #e4eaf2;

  /* Inside auto layout */
}
.icon {
  /* border: 1px solid;
  border-radius: 50px; */
  /* width:16px;
  height:16px; */
  color: #b1c3df;
  margin: 0px;
  font-size: 16px;
}
.emptyicon {
  border: 1px solid;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  color: #b1c3df;
  margin-right: 7px;
}
.subtext {
  margin: 0px;
  margin-left: 5px;
  width: 45px;
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
}
.setting_text {
  margin: 0px;
  margin-left: 10px;
  width: 81px;
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text/Nav */

  color: #556a89;
  white-space: nowrap;

  /* Inside auto layout */
}

.input2 {
  box-sizing: border-box;

  width: 412px;
  height: 32px;

  /* Nav/Border */

  border: 1px solid #bfcce0;
  border-radius: 4px;
}
.input3 {
  box-sizing: border-box;

  width: 412px;
  height: 32px;

  /* Nav/Search BG */

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Icon Light */

  color: #b1c3df;

  background: #f2f5f9;
  /* Nav/Border */

  border: 1px solid #bfcce0;
  border-radius: 4px;
}
.input1 {
  margin-left: 10%;
  width: 16px;
  height: 16px;
}
.tox-silver-sink.tox-tinymce-aux {
  z-index: 99999;
}

.disableModalText {
  width: 361px;
  height: 38px;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Text/Dark */

  color: #262626;

  margin: 0;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  margin-left: 7px;
}
.secondaryBtnText {
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Text/Nav */

  color: #556a89;
  white-space: nowrap;
  margin: 0 5px;
}
.primaryBtnText {
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  white-space: nowrap;
  margin: 0 5px;
}
.cancelBtnText {
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  white-space: nowrap;
  margin: 0 5px;
}

.successText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 0 0;
}
.successTextMain {
  width: 452px;
  height: 38px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* or 158% */
  /* Text/Nav */

  color: #556a89;
}
.savePdfText {
  width: 351px;
  height: 38px;
  margin: 0;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* or 158% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Text/Nav */

  color: #556a89;
}
.successTextsub {
  width: 242px;
  height: 13px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Icon Dark */

  color: #7e99c1;
}
/* .successTextMain */
.continue-button {
  width: 118px;
  height: 30px;

  gap: 30px;
  background: #337eed;
  border-radius: 4px;
}

.header-section {
  display: flex;
  align-items: center;
}
.value-section {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px;
}
.seperator-line {
  margin-top: '8px';
  border: 1px solid #e4eaf2;
}
.more-items-badge {
  height: 36px;
  min-width: 36px;
  background-color: #e4eaf2;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.more-items-badge svg {
  height: 14px;
  width: 14px;
}
.view-all {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  background: #337eed;
  border-radius: 4px;
}

.continue-button-text {
  width: 53px;
  height: 16px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  margin-left: 32px;
  margin-top: 6px;
}
.version-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 460px;
  height: 338px;
  overflow-y: auto;
  overflow-x: hidden;
}
.verions-item {
  box-sizing: border-box;

  width: 450px;
  min-height: 38px;
  display: flex;
  align-items: center;
  padding-left: 10px;

  border: 1px solid #e4eaf2;
  border-radius: 4px;
  margin: 0px;
  cursor: pointer;
}
.premium-banner {
  width: 468px;
  height: 28px;

  background: #fffbe6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}
.premium-banner-text {
  width: 352px;
  height: 12px;
  margin: 0;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ad6800;
}
.verions-item-text {
  width: 125px;
  height: 14px;
  margin: 0 0 0 10px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  width: 106px;
  height: 20px;

  color: #262626;
}
.versionIcon {
  width: 15px;
  height: 15px;
  background: #337eed;
}
.noVersionContainer {
  width: 226px;
  height: 159px;
  display: flex;
  flex-direction: column;
  /* align-content: flex-end; */
  align-items: center;
  /* justify-content: center; */
  margin-left: 25%;
  margin-top: 20%;
}
.noVersionText {
  width: 226px;
  height: 22px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height, or 169% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Icon Dark */

  color: #7e99c1;
}

.page-list {
  width: 100%;
  height: 273px;
  overflow-y: auto;
}

.delete_text {
  width: 361px;
  margin-top: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Text/Dark */

  color: #262626;
}

.search-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin: 4px;
  gap: 4px;
  box-sizing: border-box;
  width: -webkit-fill-available;
  height: 24px;

  /* Nav/Border */

  border: 1px solid #bfcce0;
  border-radius: 4px;

  /* Inside auto layout */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Nav/Border */

  color: #bfcce0;
}
.search-box,
input[type='text'] {
  color: black;
}
.search {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}
.search,
input[type='text']::placeholder {
  color: #bfcce0;
}

/* css selector for component createbleSelect for adding members */
.css-g1d714-ValueContainer {
  overflow: hidden !important;
}
.css-lxq0de-menu ::-webkit-scrollbar {
  display: flex !important;
}
/* .css-lxq0de-menu {

} */
.premium-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px 15px 6px;
  grid-gap: 12px;
  gap: 12px;
  /* width: 150px; */
  height: 35px;
  background: #fffbe9;
  border-radius: 4px;
}

.premium-alert-text {
  /* width: 167px; */
  height: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  display: flex;
  /* align-items: center; */
  text-align: center;
  color: #ad6800;
  flex-direction: column;
}

.PhoneInputInput {
  box-shadow: none !important;
  border: 1px solid #bfcce0 !important;
  border-radius: 4px !important;
}

.column-dropdown {
  position: absolute;
  height: 218px;
  width: 566px;
  overflow: scroll;
  background: #ffffff;
  border: 1px solid #bfcce0;
  box-shadow: 0px 4px 12px rgba(127, 154, 193, 0.16);
  border-radius: 4px;
  margin-top: 2px;
  z-index: 9999;
}
.column-dropdown::-webkit-scrollbar {
  display: -webkit-box !important;
  width: 5px !important;
}

.properties-by-column {
  width: 56px;
  height: 14px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Text/Nav */

  color: #556a89;
}
.currency_selectable {
  margin: 10px 0;
}
.currency_selectable_dropdown::-webkit-scrollbar {
  display: flex !important;
}

.property-value {
  width: 88px;
  height: 17px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* display: flex; */
  align-items: center;
  color: #262626;
}
.MuiSwitch-track {
  opacity: 1 !important;
}

.dropDownTeamMember {
  display: flex;
  justify-content: flex-end;
  margin: 0px;
}
.dropDownTeamMember .react-contexify {
  position: relative;
  margin: 25px 0 0 0;
  height: 150px !important;
  padding: 8px !important;
}
.dropDownTeamMember .react-contexify ::-webkit-scrollbar {
  display: flex !important;
}
.dropDownTeamMember .react-contexify__will-enter--scale {
  transform-origin: top;
  animation: react-contexify__slideIn 0.2s;
}
.dropDownTeamMember .react-contexify__will-leave--scale {
  transform-origin: top;
  animation: react-contexify__fadeOut;
}

.currency_selectable_dropdown {
  box-sizing: border-box;

  /* Auto layout */
  padding: 12px;
  overflow: auto;
  height: 156px;
  background: #ffffff;
  /* Nav/Border */
  border: 1px solid #bfcce0;
  box-shadow: 0px 4px 12px rgba(127, 154, 193, 0.16);
  border-radius: 4px;
}
.currency_selectable_div {
  /* width: 568px; */
  height: 36px;
  /* Nav/Border */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #bfcce0;
  border-radius: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 10px 0;
}
.currency_option {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 4px;
  height: 30px;
  background: #ffffff;
}

.mobile-verify {
  width: 436px;
  height: 36px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* or 123% */

  text-align: center;

  /* Text Black */

  color: #49454f;
}
.mobile-verify-heading {
  width: 198px;
  height: 19px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  /* Text Black */

  color: #49454f;
}
.email-verify {
  width: 436px;
  height: 36px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* or 123% */

  text-align: center;

  /* Text Black */

  color: #49454f;
}
.email-verify-heading {
  width: 198px;
  height: 19px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  /* Text Black */

  color: #49454f;
}

.profile-heading {
  /* width: 100%; */
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* Text/Dark */

  color: #262626;
  /* padding-left: 17%; */
  white-space: nowrap;
}
.profile-sub-heading {
  width: 100%;
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #556a89;
  /* padding-left: 17%; */
}

.empty-search-tl {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
}
.empty-search-tl:hover {
  background: #ffffff;
}
.empty-search-text-tl {
  width: 167px;
  height: 32px;
  left: 17px;
  top: 130px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray/900 */

  color: #556a89;
}

.fade-in-modal {
  transition-timing-function: ease-in;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out-modal {
  transition-timing-function: ease-in;
  animation: fadeOut 250s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.leftSection {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;
}
